import jwt_decode, { JwtPayload } from 'jwt-decode';
import { MarketplaceUserProductsPermissions } from '@common/services/marketplace-product.service';
import { WhiteLabelInfo } from '@common/models/white-label-info';

export enum USER_AUTHORITIES {
  PRICING_TOOL_CLIENT = 'Pricing Tool Client',
  PRODUCT_MANAGER = 'Product Manager',
  PRICING_TOOL_ISSUER = 'Pricing Tool Issuer',
  PRICING_TOOL_ISSUER_APPROVER = 'Pricing Tool Issuer Approver',
  ISSUER_PRODUCT_MANAGER = 'Issuer Product Manager',
  ADMINISTRATOR = 'Administrator',
  SALES_ASSISTANT = 'Sales Assistant',
  REGISTERED_REPRESENTATIVE = 'Registered Representative',
  NON_SELLING_BACK_OFFICE = 'Non Selling Back Office',
  PRICING_TOOL_CLIENT_APPROVER = 'Pricing Tool Client Approver',
  TERMINATED = 'Terminated',
  PENDING_REQUESTS = 'Pending Requests',
  NON_SELLING_FRONT_OFFICE = 'Non Selling Front Office',
}

export class User {
  id: string;
  name: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  company?: string;
  logoutUrl?: string;
  authorities: string[];
  uuid: string;
  primarySplitId?: string;
  splitIds?: string[];
  programIds?: string;
  permissions: string[] = [];
  preferences: object = {};
  userDisplayConfig: UserDisplayConfig;
  token: string;
  refreshToken?: string;
  cst?: string;
  trainingData: MarketplaceUserProductsPermissions;
  whiteLabelInfo: WhiteLabelInfo;
  readableExpTime: Date;
  // hack from 3/24/2022: wholesaler needs to be determined for if conditions on api calls.
  isWholesaler: boolean;
  pricingToolType: string[];

  private jwt: Jwt;

  constructor(token: string) {
    this.setJWT(token);
  }

  setJWT(token) {
    this.token = token;
    this.jwt = jwt_decode(token);
    this.id = this.jwt.user_name;
    this.firstName = this.jwt.firstName;
    this.lastName = this.jwt.lastName;
    this.name = this.jwt.name;
    this.company = this.jwt.company;
    this.authorities = this.jwt.authorities;
    this.uuid = this.jwt.uuid;
    this.primarySplitId = this.jwt.primarySplitId;
    this.splitIds = this.jwt.splitIds;
    this.programIds = this.jwt.programIds;
  }

  hasJwtPermission(jwtPermission: string) {
    return this.jwt?.permissions?.includes(jwtPermission);
  }

  isLoggedIn(): boolean {
    if (this.token) {
      const expiration = this.refreshToken ? (jwt_decode(this.refreshToken) as JwtPayload).exp : this.jwt.exp;
      return Date.now() + 10000 < expiration * 1000;  // padding 10 secands to properly handle logout on session expiration
    }                                                 // see userService.initWatchForSessionTimeout()
    return false;
  }

  canAccess(widget: string): boolean {
    return this.permissions.some(x => x?.toLowerCase() === widget?.toLowerCase());
  }

  get defaultProgramId(): number | null {
    if (this.programIds) {
      const programIdSplit = this.programIds?.split(',');
      if (programIdSplit?.length === 1) {
        const programId = parseFloat(programIdSplit[0]);
        if (!isNaN(programId) && programId > 0) {
          return programId;
        }
      }
    }

    return null;
  }
}

interface Jwt {
  user_name: string;
  exp: number;
  name?: string;
  company?: string;
  uuid?: string;
  firstName?: string;
  authorities?: string[];
  lastName?: string;
  permissions: string[];
  primarySplitId?: string;
  splitIds?: string[];
  programIds: string;
}

export interface UserDisplayConfig {
  deskId: number;
  displayExternalStructureName: boolean;
  displayBloombergTicker: boolean;
  displayProductSymbol: boolean;
  dailySummaryOccurrence: string;
  displayLegacyDashboard: any;
  displayLegacyNavigation: any;
  marketDataSourcePreference: any;
  hideDashboardWidgets: boolean;
  hideAllDisclaimer: boolean;
  hideHomeMenu: boolean;
  hideProductMarketplaceMenu: boolean;
  displayMarkToMarketSource: boolean;
  enableDashboardInsights: boolean;
  displayLegacyLifecycleManager: boolean;
  id: string;
  showIncomeCompare: boolean;
}
