import { Component, OnInit, ViewChild, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { Router, NavigationEnd} from '@angular/router';
import { Subscription } from 'rxjs';
import { takeWhile, filter } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { UserService } from '@common/services/user.service';
import { environment } from '@environments/environment';
import { NavService } from '@common/services/nav.service';
import { onMainContentChange } from './animations/animations';
import { Utilities } from '@common/utilities/utilities';
import { SpinnerService } from '@common/services/spinner.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { WhiteLabelInfo } from '@common/models/white-label-info';
import { SessionService } from './session.service';
import { NavigationConstants } from '@constants/navigation.constants';
import { ErrorModalComponent } from '@common/error-modal/error-modal.component';

declare var dataLayer;
declare let dT_;

export const USER_TOKEN_COOKIE ='user_token';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [onMainContentChange]
})
export class AppComponent implements OnInit, OnDestroy {
  alive = true;
  @ViewChild('container') public container: ElementRef;
  hideOverflowRoutes = [
    '/learning-center/northpass'
  ];
  iFrameRoutes = [
    '/learning-center/northpass-app',
    '/learning-center/sso',
    '/luma-creation-hub',
    '/luma-creation-hub/overview',
    '/luma-creation-hub/draft-request',
    '/product-marketplace/order-form',
    '/product-marketplace/view-order-report',
    '/sso-required'
  ];
  insideIframe = false;
  hideFooterForDesk: boolean;
  whiteLabelInfo: WhiteLabelInfo;
  defaultProgramId: number | null;
  notifiedAboutTimeout = false;

  sideNavState: string;
  showHeader = false;
  showFooter = false;
  showSidenav = false;

  spinnerTimeout;

  urlActions = [
    {
      url: 'product-marketplace/structured-products',
      action: 'enterPM'
    },
    {
      url: 'lifecycle-manager/overview',
      action: 'enterLM'
    },
    {
      url: 'dashboard',
      action: 'enterDB'
    },
    {
      url: 'earning-center/overview',
      action: 'enterLC'
    }
  ];
  private notifyUserSub: Subscription;
  private errorModalDialogRef: MatDialogRef<ErrorModalComponent, any>;

  constructor(
    private titleService: Title,
    private router: Router,
    private userService: UserService,
    private http: HttpClient,
    private navService: NavService,
    private renderer2: Renderer2,
    private sessionService: SessionService,
    private spinnerService: SpinnerService,
    private dialog: MatDialog
  ) {
    this.hideFooterForDesk = this.userService.getUser()?.userDisplayConfig?.hideAllDisclaimer;
    this.insideIframe = this.insideIframeCheck();

    // start dynatrace tracking
    if(environment.isDynatraceEnabled) {
      if(typeof dT_ !== 'undefined' && dT_.initAngularNg){dT_.initAngularNg(this.http, HttpHeaders);}
    }

    this.navService.sideNavState.pipe(takeWhile(() => this.alive)).subscribe(res => this.sideNavState = res);
    this.navService.showHeader.pipe(takeWhile(() => this.alive)).subscribe(res => this.showHeader = res);
    this.navService.showFooter.pipe(takeWhile(() => this.alive)).subscribe(res => this.showFooter = res);
    this.navService.showSidenav.pipe(takeWhile(() => this.alive)).subscribe(res => this.showSidenav = res);
  }

  hideShowCFrame(cleanPath) {
    if(cleanPath === NavigationConstants.LOGIN || cleanPath === NavigationConstants.LOGOUT || this.insideIframe) {
      this.navService.showHeader.next(false);
      this.navService.showSidenav.next(false);
      this.navService.showFooter.next(false);
    } else if(cleanPath.indexOf('creation-hub-ubs') !== -1) {
      this.navService.showSidenav.next(false);
      this.navService.showFooter.next(false);
    } else {
      this.navService.showHeader.next(true);
      this.navService.showSidenav.next(true);
      this.navService.showFooter.next(true);
    }
  }

  ngOnInit() {
    this.userService.$user.pipe(filter(user => user != null),takeWhile(_=> this.whiteLabelInfo == null && this.alive)).subscribe(user => {
      if (user != null) {
        this.whiteLabelInfo = user.whiteLabelInfo;
        this.defaultProgramId = user.defaultProgramId;
      }
    });

    this.sessionService.filteredStartUrl.pipe(takeWhile(() => this.alive)).subscribe((cleanUrl: string) => {
      this.updateTitleAndShowSpinner(cleanUrl, true);
      this.spinnerTimeout = setTimeout(() => {
        this.spinnerService.showSpinner();
      }, 33);
    });

    this.sessionService.filteredNavEnd.pipe(takeWhile(() => this.alive)).subscribe((navigationEnd: NavigationEnd) => {
      const cleanUrl = Utilities.cleanUrl(navigationEnd.url);
      this.updateTitleAndShowSpinner(cleanUrl);
      this.hideShowCFrame(cleanUrl);
    });

    this.sessionService.filteredNavEnd.subscribe({next: () => {
        if (this.spinnerTimeout != null) {
          clearTimeout(this.spinnerTimeout);
        }
      }});

    this.notifyUserSub = this.userService.notifyUser$.subscribe(res => {
      const data = this.getErrorResponseObj(res);
      const message = data.uiMessage;
      const notifyUser = 
        (message || this.isErrorStatus(res.data?.status)) && 
        Utilities.cleanUrl(this.router.url).indexOf('creation-hub-ubs') !== -1 &&
        this.shouldNotifyAboutTimeout(data?.content?.timeout);
      const isOpen = this.errorModalDialogRef?.getState() === MatDialogState.OPEN;
      if (notifyUser && !isOpen) {
        this.showNotifyModal(res.type, message);
      }
    });
  }

  getErrorResponseObj(response) {
    return response.data?.error || response.data?.body || {};
  }

  isErrorStatus(status: number): boolean {
    return status === 404 || status?.toString()[0] === '5';
  }

  shouldNotifyAboutTimeout(timeout: boolean | undefined) {
    if (!this.notifiedAboutTimeout && timeout) {
      this.notifiedAboutTimeout = true;
      return true;
    }
    return false;
  }

  showNotifyModal(type: string, message: string): void {
    const data: any  = {};
    data.type = type;
    data.message = message;

    this.errorModalDialogRef = this.dialog.open(ErrorModalComponent, {
      width: '616px',
      panelClass: 'confirmation-dialog',
      data,
    });
  }

  private updateTitleAndShowSpinner(cleanUrl, showSpinner = false) {
    if(cleanUrl=== NavigationConstants.LOGIN || window.location.pathname === NavigationConstants.LOGIN) {
      this.titleService.setTitle('Luma Platform Log In');
    } else {
      this.titleService.setTitle('Luma');
      if (showSpinner) {
        this.spinnerService.showSpinner();
      }
    }
  }

  onRouteChange(event) {
    document.getElementsByTagName('mat-sidenav-content')[0].scrollTo(0, 0);
    this.notifiedAboutTimeout = false;
  }

  hideOverflowOnMainContainer() {
    return this.hideOverflowRoutes.some(x => x === window.location.pathname);
  }
  insideIframeCheck() {
    if(window.location.pathname === '/product-marketplace/view-order-report') {
      this.renderer2.setStyle(this.container.nativeElement, 'width', '93%');
      this.renderer2.setStyle(this.container.nativeElement, 'height', '200px');
    }
    return (Utilities.foundInArray(this.iFrameRoutes, window.location.pathname) || window.location.href.includes(`iframe=true`));
  }

  ngOnDestroy() {
    this.alive = false;
    this.notifiedAboutTimeout = false;
    this.notifyUserSub.unsubscribe();
  }
}
